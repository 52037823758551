import { Injectable } from '@angular/core';
import { ToastService, ToastType } from '../toast.service';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { SignInWithApple, SignInWithAppleOptions, SignInWithAppleResponse } from '@capacitor-community/apple-sign-in';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { Capacitor } from '@capacitor/core';
import { FacebookLoginResponse, SocialLogin } from '@capgo/capacitor-social-login';
import { GoogleLoginResponseOnline, ProviderResponseMap } from '@capgo/capacitor-social-login/dist/esm/definitions';
import { GoogleLoginService } from './google-login.service';
import { Feature, LoggerService } from '../logger.service';
import { ModalService } from '../modal.service';
import { AppService, PlatformType } from '../app.service';
import { UserLocationState } from 'src/app/store/user-location.state';
import { SocialLoginProvider } from '../../providers/social-login.provider';
import { User, UserType } from 'src/app/store/user.state';

@Injectable({ providedIn: 'root' })
export class SocialLoginService {
  readonly googleInizialized$ = new BehaviorSubject(false);
  readonly facebookInitialized$ = new BehaviorSubject(false);

  private readonly googleOAuthRedirectUri = (window.location.hostname === 'localhost' ? 'https://localhost:8100' : environment.host) + '/authentication/google';

  // If you need to update this value, update the id (and the associated token) in these files:
  // ios/App/App/Info.plist
  // android/app/src/main/res/valuse/strings.xml
  private readonly FACEBOOK_CONFIG = {
    appId: '119313188407270',
    token: 'c4c33c5400225f6bbd787f4c321beca8',
  };

  // If you need to update this value, update the id (and the associated token) in these files:
  // ios/App/App/Info.plist
  private readonly IOS_CONFIG: SignInWithAppleOptions = {
    clientId: 'org.worldreader.booksmart.signin', // See https://developer.apple.com/account/resources/identifiers/serviceId
    redirectURI: environment.host,
    scopes: 'email',
    state: '12345',
  };

  private readonly GOOGLE_CONFIG = {
    web: '1025042132072-v6rqp3ae1bukj9ni62u0spcuajp7q0gc.apps.googleusercontent.com',
    iOS: '1025042132072-n05b10iiitln9cs3hs00tr57u469am1a.apps.googleusercontent.com',
  };

  constructor(
    private toastService: ToastService,
    private loggerService: LoggerService,
    private userLocationState: UserLocationState,
    private modalService: ModalService,
    private socialLoginProvider: SocialLoginProvider,
    private translateService: TranslateService,
    private appService: AppService,
    private googleService: GoogleLoginService,
  ) {}

  // https://github.com/capacitor-community/facebook-login/pull/150
  async facebookLogin(): Promise<{ user: User; isNewUser: boolean } | void> {
    await this.initializeFacebookLogin();

    if (!this.facebookInitialized$.value) {
      return;
    }

    const limitedLogin = this.appService.platformType === PlatformType.IOS;

    const result: void | {
      provider: 'facebook';
      result: ProviderResponseMap['facebook'];
    } = await SocialLogin.login({
      provider: 'facebook',
      options: {
        permissions: [],
        limitedLogin: limitedLogin,
      },
    }).catch(error => {
      if (error?.accessToken?.token === null || error.message === 'Login cancelled') {
        // User closed the page, do nothing
      } else {
        this.loggerService.error('Cannot login via facebook ' + error.toString(), {
          context: 'FacebookService::login() - Cannot login via facebook',
          feature: Feature.AUTHENTICATION,
        });
        this.showLoginFailedToast('facebook');
      }
    });

    const data = result?.result as FacebookLoginResponse;

    const facebookId = data.accessToken?.userId || data.profile.userID;
    const accessToken = data.idToken || data.accessToken?.token;

    if (accessToken && facebookId) {
      const user = await firstValueFrom(
        this.socialLoginProvider.loginWithFacebook(facebookId, accessToken, this.userLocationState.userLocation$.value.countryCode, limitedLogin),
      ).catch(error => {
        void this.toastService.present({
          message: 'authentication with facebook failed. Try again later or login with an other provider',
          type: ToastType.Negative,
          displayClose: false,
        });

        this.loggerService.error('FacebookService::login() - Cannot login via facebook - Booksmart API', {
          context: JSON.stringify(error),
          feature: Feature.AUTHENTICATION,
        });
      });

      if (user) {
        return { user, isNewUser: user.isNewAccount };
      } else {
        void this.showLoginFailedToast('facebook');

        return Promise.reject();
      }
    } else {
      this.loggerService.error('Cannot login via facebook (iOS)', {
        context: 'FacebookService::login() - Cannot login via facebook (iOS - null token)',
        feature: Feature.AUTHENTICATION,
      });
      void this.showLoginFailedToast('facebook');

      return Promise.reject();
    }
  }

  async appleLogin(): Promise<{ user: User; isNewUser: boolean } | void> {
    const result: void | SignInWithAppleResponse = await SignInWithApple.authorize(this.IOS_CONFIG).catch((error: { error: string }) => {
      if (error?.error !== 'popup_closed_by_user') {
        // Do nothing
      }
    });

    if (result) {
      const user = await firstValueFrom(
        this.socialLoginProvider.loginWithApple(result.response.identityToken, result.response.authorizationCode, this.userLocationState.userLocation$.value.countryCode),
      ).catch(error => {
        if (error.code === 'EMAIL_ALREADY_EXISTS') {
          void this.toastService.present({
            message: this.translateService.instant('PWA_LoginOrCreateAccount_loginError_apple_email_taken'),
            type: ToastType.Informative,
            displayClose: false,
            duration: 10000,
          });
        } else {
          this.loggerService.error('SocialLoginService::appleLogin() - Cannot login via google - provider error', {
            context: JSON.stringify(error),
            feature: Feature.AUTHENTICATION,
          });

          this.showLoginFailedToast('apple');
        }
      });

      if (user) {
        void this.modalService.dismiss();

        return { user, isNewUser: user.isNewAccount };
      } else {
        return Promise.reject();
      }
    }
  }

  async googleLogin(): Promise<{ user: User; isNewUser: boolean } | void> {
    // Web
    if (this.appService.platformType !== PlatformType.Android && this.appService.platformType !== PlatformType.IOS) {
      this.googleService.loginWithGoogle(this.googleOAuthRedirectUri);
      return Promise.resolve();
    }

    // Android and iOS

    await this.initializeGoogleLogin();

    if (!this.googleInizialized$.value) {
      return;
    }

    const result: void | {
      provider: 'google';
      result: ProviderResponseMap['google'];
    } = await SocialLogin.login({
      provider: 'google',
      options: {
        scopes: ['profile', 'email'],
        forceRefreshToken: false, // if you need refresh token
      },
    }).catch(error => {
      if (error.message === 'Login cancelled' || error.message.includes('The user canceled the sign-in flow.')) {
        // Do nothing
      } else {
        this.loggerService.error('SocialLoginService::googleLogin() - Cannot login via google - GoogleAuth plugin', {
          context: error.toString(),
          feature: Feature.AUTHENTICATION,
          duration: 10000,
        });
        this.showLoginFailedToast('google');
      }
    });

    const data = result?.result as GoogleLoginResponseOnline;

    if (data && data.idToken && data.profile?.id) {
      const user = await firstValueFrom(
        this.socialLoginProvider.loginWithGoogle(
          data.profile?.id,
          data.idToken,
          Capacitor.getPlatform() !== 'ios' && Capacitor.getPlatform() !== 'android' ? 'web' : Capacitor.getPlatform(),
          this.userLocationState.userLocation$.value.countryCode,
        ),
      ).catch(error => {
        if (error.code === 'EMAIL_ALREADY_EXISTS') {
          void this.toastService.present({
            message: this.translateService.instant('PWA_LoginOrCreateAccount_loginError_google_email_taken'),
            type: ToastType.Informative,
            displayClose: false,
          });
        } else {
          this.loggerService.error('SocialLoginService::googleLogin() - Cannot login via google - provider error', {
            context: JSON.stringify(error),
            feature: Feature.AUTHENTICATION,
          });

          this.showLoginFailedToast('google');
        }
      });

      if (user) {
        return { user, isNewUser: user.isNewAccount };
      }
    } else {
      return Promise.reject();
    }
  }

  // Web only
  async checkGoogleOAuthToken(state: string, code: string): Promise<{ user: User; isNewUser: boolean } | void> {
    const user = await firstValueFrom(
      this.socialLoginProvider.loginWithGoogleWeb(state, code, this.googleOAuthRedirectUri, this.userLocationState.userLocation$.value.countryCode),
    ).catch(error => {
      if (error.code === 'EMAIL_ALREADY_EXISTS') {
        void this.toastService.present({
          message: this.translateService.instant('PWA_LoginOrCreateAccount_loginError_google_email_taken'),
          type: ToastType.Informative,
          displayClose: false,
        });
      } else {
        this.loggerService.error('SocialLoginService::checkGoogleOAuthToken() - Cannot login via google - provider error', {
          context: JSON.stringify(error),
          feature: Feature.AUTHENTICATION,
        });

        this.showLoginFailedToast('google');
      }
    });

    if (user) {
      return { user, isNewUser: user.isNewAccount };
    } else {
      return Promise.reject();
    }
  }

  async logout(userType: UserType): Promise<void> {
    switch (userType) {
      case UserType.GOOGLE:
        await this.initializeGoogleLogin();
        void SocialLogin.logout({ provider: 'google' });
        break;
      case UserType.FACEBOOK:
        await this.initializeFacebookLogin();
        void SocialLogin.logout({ provider: 'facebook' });
        break;
      case UserType.APPLE:
        // No action needed here
        break;
    }
  }

  private async initializeFacebookLogin(): Promise<void> {
    if (!this.facebookInitialized$.value) {
      await SocialLogin.initialize({
        facebook: {
          appId: this.FACEBOOK_CONFIG.appId,
          clientToken: this.FACEBOOK_CONFIG.token,
        },
      }).catch(error => {
        this.loggerService.error('SocialLoginService::initializeFacebookLogin() - initialize', {
          context: 'Cannot login via facebook ' + error.toString(),
          feature: Feature.AUTHENTICATION,
        });
        this.showLoginFailedToast('facebook');
      });

      this.facebookInitialized$.next(true);
    }
  }

  private async initializeGoogleLogin(): Promise<void> {
    if (!this.googleInizialized$.value) {
      await SocialLogin.initialize({
        google: {
          iOSClientId: this.GOOGLE_CONFIG.iOS,
          iOSServerClientId: this.GOOGLE_CONFIG.iOS,
          webClientId: this.GOOGLE_CONFIG.web,
          mode: 'online',
        },
      }).catch(error => {
        this.loggerService.error('SocialLoginService::initializeGoogleLogin() - initialize', {
          context: 'Cannot login via google ' + error.toString(),
          feature: Feature.AUTHENTICATION,
        });
        this.showLoginFailedToast('google');
      });

      this.googleInizialized$.next(true);
    }
  }

  private showLoginFailedToast(provider: 'apple' | 'facebook' | 'google'): void {
    let message;

    switch (provider) {
      case 'apple':
        message = this.translateService.instant('PWA_LoginOrCreateAccount_loginError_methodFailed_apple');
        break;
      case 'facebook':
        message = this.translateService.instant('PWA_LoginOrCreateAccount_loginError_methodFailed_facebook');
        break;
      case 'google':
        message = this.translateService.instant('PWA_LoginOrCreateAccount_loginError_methodFailed_google');
        break;
    }

    void this.toastService.present({
      message,
      type: ToastType.Negative,
      displayClose: false,
      cssClass: 'social-login-error-toast',
    });
  }
}
